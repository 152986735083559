import { useMediaQuery } from '@vueuse/core'

export enum Breakpoints {
  'mn' = '400px',
  'sm' = '640px',
  'md' = '768px',
  'tablet' = '920px',
  'lg' = '1024px',
  'xl' = '1280px',
  '2xl' = '1536px',
  'mobile' = '400px',
}
export const MEDIA_MN = useMediaQuery(`(min-width: ${Breakpoints.mn})`)
export const MEDIA_SM = useMediaQuery(`(min-width: ${Breakpoints.sm})`)
export const MEDIA_MD = useMediaQuery(`(min-width: ${Breakpoints.md})`)
export const MEDIA_TABLET = useMediaQuery(`(min-width: ${Breakpoints.tablet})`)
export const MEDIA_LG = useMediaQuery(`(min-width: ${Breakpoints.lg})`)
export const MEDIA_XL = useMediaQuery(`(min-width: ${Breakpoints.xl})`)
export const MEDIA_2XL = useMediaQuery(`(min-width: ${Breakpoints['2xl']})`)
