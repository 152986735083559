import { getObjectKey, isArray, isValKey } from '@qcwp/utils'
// import areaData from './area'
import cityData from './city'
import nestData from './nest'
import { cityKey, cityShortKey } from './cityKey'
import provinceData from './province'
import { provinceKey, provinceShortKey } from './provinceKey'

export interface Province {
  id: string
  name: string
  short_name: string
  f_id: string
}
export interface City extends Province {}
export interface Area extends Province {}
export interface NestProvince extends Province {
  children?: NestProvince[]
}
export * from './cityKey'
export * from './provinceKey'
// export const area = areaData
export const city = cityData
export const province = provinceData
export const nestProvince = nestData

export const provinceName = (data: string | string[], solid = '、', isShort = true): string => {
  if (!data)
    return ''
  if (isArray(data)) {
    const arr: string[] = []
    data.forEach((v) => {
      arr.push(provinceName(v, '', isShort))
    })
    return arr.join(solid)
  }
  else {
    const provinceData = isShort ? provinceShortKey : provinceKey
    if (isValKey(provinceData, data))
      return (provinceData as any)[data]
  }
  return ''
}
export function provinceId(data: string, isShort: boolean): string | undefined
export function provinceId(data: string[], isShort: boolean): (string | undefined)[]
export function provinceId(data: string | string[], isShort = true): string | undefined | (string | undefined)[] {
  if (!data)
    return undefined
  if (isArray(data)) {
    const arr: (string | undefined)[] = []
    data.forEach((v) => {
      arr.push(provinceId(v, isShort))
    })
    return arr
  }
  else {
    const provinceData = isShort ? provinceShortKey : provinceKey
    return getObjectKey(provinceData, data)
  }
}

export const cityName = (data: string | string[], solid = '、', isShort = true): string => {
  if (!data)
    return ''
  if (isArray(data)) {
    const arr: string[] = []
    data.forEach((v) => {
      arr.push(cityName(v, '', isShort))
    })
    return arr.join(solid)
  }
  else {
    const cityData = isShort ? cityShortKey : cityKey
    if (isValKey(cityData, data))
      return (cityData as any)[data]
  }
  return ''
}
export function cityId(data: string, isShort: boolean): string | undefined
export function cityId(data: string[], isShort: boolean): (string | undefined)[]
export function cityId(data: string | string[], isShort = true): string | undefined | (string | undefined)[] {
  if (!data)
    return undefined
  if (isArray(data)) {
    const arr: (string | undefined)[] = []
    data.forEach((v) => {
      arr.push(provinceId(v, isShort))
    })
    return arr
  }
  else {
    const cityData = isShort ? cityShortKey : cityKey
    return getObjectKey(cityData, data)
  }
}

/**
 * 获取省市简称拼接字符串
 * @param row {provinceId, cityId}
 * @returns string
 */
export function getSortAreaText<T extends { provinceId: string | number | null; cityId: string | number | null }>(row: T): string {
  const area = [provinceName(row.provinceId?.toString() || ''), cityName(row.cityId?.toString() || '')].filter(v => !!v)
  return Array.from(new Set(area)).join('')
}
