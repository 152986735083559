/** **************** 页面路径处理 *************/

import qs from 'qs'
import { addHttp, queryUrl } from '@qcwp/utils'
import { useRoute } from 'vue-router'
import type { TOPIC_COMPONENT_TYPE } from '../const'
import { ARTICLE_STATUS, ARTICLE_TYPE, BANNER_DATA_TYPE, TOPIC_COMPONENT_DATA_TYPE } from '../const'

import type { ArticleRecommendItem } from '../types'
import type { LoginPageQuery, RouterPlatform } from './type'
import { WEB_ROUTE_PATH, goAnyPage } from './index'
/**
 * 登录路径
 * @returns string
 */
export function loginPath(query?: LoginPageQuery) {
  const route = useRoute()
  if (!query)
    query = {}
  query.redirect = query?.redirect || ((route?.fullPath && !([WEB_ROUTE_PATH.forget, WEB_ROUTE_PATH.register, WEB_ROUTE_PATH.login] as string[]).includes(route?.fullPath)) ? route?.fullPath : '/')
  // query.redirect = encodeURIComponent(query.redirect)

  return WEB_ROUTE_PATH.login + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 注册路径
 * @returns string
 */
export function registerPath() {
  return WEB_ROUTE_PATH.register
}
/**
 * 忘记密码路径
 * @returns string
 */
export function forgetPath() {
  return WEB_ROUTE_PATH.forget
}
/**
 * 首页路径
 * @returns string
 */
export function homePath() {
  return WEB_ROUTE_PATH.home
}
/**
 * 网评号首页
 * @returns string
 */
export function mediaHomePath() {
  return WEB_ROUTE_PATH.mediaHome
}

/**
 * 资讯分类
 * @returns string
 */
export function newsTypePath(typeId: number | string) {
  return WEB_ROUTE_PATH.newsType.replace('[typeId]', String(typeId))
}
/**
 * 作者详情
 * @returns string
 */
export function articleAuthorPath(authorId: string, query?: { page?: number; type?: ARTICLE_TYPE }) {
  const queryString = query && queryUrl(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.articleAuthor.replace('[authorId]', `${authorId}`) + (queryString || '')
}

/**
 * 资讯首页
 * @returns string
 */
export function newsHomePath() {
  return WEB_ROUTE_PATH.newsHome
}
/**
 * 专题大全
 * @returns string
 */
export function newsTopicPath() {
  return WEB_ROUTE_PATH.newsTopicPath
}
/**
 * 专题详情
 * @returns string
 */
export function topicDetailPath(topicId: string) {
  return WEB_ROUTE_PATH.newsTopicDetailPath.replace('[topicId]', String(topicId))
}
/**
 * 行情导购
 * @returns string
 */
export function newsHangQingPath() {
  return WEB_ROUTE_PATH.newsHangQingPath
}
/**
 * 视频
 * @returns string
 */
export function videoHomePath() {
  return WEB_ROUTE_PATH.videoHome
}
export function videoListPath() {
  return WEB_ROUTE_PATH.videoList
}
export function liveHomePath() {
  return WEB_ROUTE_PATH.liveHome
}

/**
 * 资讯详情
 * @returns string
 */
export function newsDetailPath(articleId: string, isAuthor?: boolean, query?: Record<string, unknown>) {
  query = query || {}
  if (isAuthor)
    query.user = 1
  return WEB_ROUTE_PATH.newsDetail.replace('[articleId]', String(articleId)) + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 视频详情
 * @returns string
 */
export function videoDetailPath(id: string, isAuthor?: boolean, query?: Record<string, unknown>) {
  query = query || {}
  if (isAuthor)
    query.user = 1
  return WEB_ROUTE_PATH.videoDetail.replace('[videoId]', String(id)) + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 直播详情
 * @returns string
 */
export function liveDetailPath(id: string, isAuthor?: boolean, query?: Record<string, unknown>) {
  query = query || {}
  if (isAuthor)
    query.user = 1

  return WEB_ROUTE_PATH.liveDetail.replace('[liveId]', String(id)) + qs.stringify(query, { addQueryPrefix: true })
}

export function dailyPath(id?: string) {
  const query: Record<string, unknown> = {}
  if (id)
    query.id = id
  return WEB_ROUTE_PATH.daily + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 资讯|视频|直播详情页地址
 * @param type string
 * @param article { id?: string; linkUrl?: string }
 */
export function articleDetailPath(type: ARTICLE_TYPE, article: { id?: string; linkUrl?: string; [key: string]: any; isSwiper?: boolean }, isAuthor?: boolean) {
  const { id = '', linkUrl = '', isSwiper = false } = article
  isAuthor = [ARTICLE_STATUS.REVIEWED].includes(article.articleStatus) ? false : isAuthor
  const query: Record<string, unknown> = {}
  if (isSwiper)
    query.swiper = true
  switch (type) {
    case ARTICLE_TYPE.LINK:
      return addHttp(linkUrl)
    case ARTICLE_TYPE.LINK_VIDEO:
      return addHttp(linkUrl)
    case ARTICLE_TYPE.VIDEO:
      // 如果认证通过则正常显示不使用预览
      return videoDetailPath(id, isAuthor, query)
    case ARTICLE_TYPE.LIVE:
      return liveDetailPath(id, isAuthor, query)
    default:
      return newsDetailPath(id, isAuthor, query)
  }
}

/**
 * 汽车首页
 * @returns string
 */
export function carHomePath(brandId?: string, venderId?: string) {
  return WEB_ROUTE_PATH.carHome + (brandId ? `/${brandId}` : '') + (venderId ? `/${venderId}` : '')
}
/**
 * 汽车综合筛选
 * @returns string
 */
export function carZhHomePath(params?: string, query?: Record<string, string>) {
  const queryString = query && queryUrl(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.carZhHome + (params ? `/${encodeURIComponent(params)}` : '') + (queryString || '')
}

export function carTestDrivePath(query?: Record<string, string>) {
  const queryString = query && queryUrl(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.carTestDrive + (queryString || '')
}
/**
 * 口碑首页
 * @returns string
 */
export function reputationHomePath(query?: string) {
  return WEB_ROUTE_PATH.reputationHome + (query ? `/${encodeURIComponent(query)}` : '')
}
/**
 * 口碑发布
 * @returns string
 */
export function reputationPublishPath(query?: string) {
  return WEB_ROUTE_PATH.reputationPublish + (query ? `/${query}` : '')
}

/**
 * 车系详情首页
 * @returns string
 */
export function seriesHomePath(id: string) {
  return WEB_ROUTE_PATH.seriesHome.replace('[seriesId]', String(id))
}
/**
 * 车系详情参数
 * @returns string
 */
export function seriesParameterPath(id: string, modelId?: string) {
  return WEB_ROUTE_PATH.seriesParameter.replace('[seriesId]', String(id)) + (modelId ? `/${modelId}` : '')
}
/**
 * 车系详情价格
 * @returns string
 */
export function seriesPricePath(id: string, query?: Record<string, string>) {
  const q = qs.stringify(query, { addQueryPrefix: true })
  const url = WEB_ROUTE_PATH.seriesPrice.replace('[seriesId]', String(id))
  return `${url}${q}`
}
/**
 * 询价
 */
export function inquiryPricePath(seriesId: string, modelId: string) {
  const url = WEB_ROUTE_PATH.inquiryPrice.replace('[seriesId]', String(seriesId)).replace('[modelId]', String(modelId))
  return `${url}`
}
/**
 * 车系详情口碑
 * @returns string
 */
export function seriesPraisePath(seriesId: string, query?: Record<string, string>) {
  const url = WEB_ROUTE_PATH.seriesPraise.replace('[seriesId]', String(seriesId))
  return url + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 车系详情资讯
 * @returns string
 */
export function seriesArticlePath(id: string, type?: string, query?: Record<string, string>) {
  // const url = WEB_ROUTE_PATH.seriesArticle.replace('[seriesId]', String(id))
  const url = WEB_ROUTE_PATH.seriesArticle.replace('[seriesId]', String(id))
  return (type ? `${url}/${type}` : url) + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 车系详情视频资讯
 * @returns string
 */
export function seriesArticleVideoPath(id: string, type?: string, query?: Record<string, string>) {
  // const url = WEB_ROUTE_PATH.seriesArticle.replace('[seriesId]', String(id))
  const url = WEB_ROUTE_PATH.seriesArticleVideo.replace('[seriesId]', String(id))
  return (type ? `${url}/${type}` : url) + qs.stringify(query, { addQueryPrefix: true })
}
/**
 * 车型图库首页
 * @returns string
 */
export function carImgHomePath() {
  return WEB_ROUTE_PATH.carImgHome
}
/**
 * 车系车型图
 * @returns string
 */
export function carImgSeriesPath(data: { seriesId: string; query?: { modelId?: string; type?: string } }) {
  const queryStr = qs.stringify(data.query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.carImgSeries.replace('[seriesId]', String(data.seriesId)) + queryStr
}
/**
 * 车系对比
 * @returns string
 */
export function carDuibiPath() {
  return WEB_ROUTE_PATH.carDuibi
}

/** ******************************************************************************/
/** ******************************** 经销商 *************************************/

/**
 * 全部经销商首页
 * @returns string
 */
export function dealerFindPath(params?: string, query?: Record<string, string>) {
  const q = qs.stringify(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.dealerFind + (params ? `/${params}` : '') + q
}
/**
 * 经销商详情首页
 * @returns string
 */
export function dealerDetailPath(dealerId: string) {
  // return WEB_ROUTE_PATH.dealerDetailIntroduce.replace('[dealerId]', String(dealerId))
  // TODO:next
  return WEB_ROUTE_PATH.dealerDetail.replace('[dealerId]', String(dealerId))
}
/**
 * 经销商详情车型报价
 * @returns string
 */
export function dealerDetailModelPath(dealerId: string, query?: Record<string, string>) {
  const q = qs.stringify(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.dealerDetailModel.replace('[dealerId]', String(dealerId)) + q
}
/**
 * 经销商详情促销信息
 * @returns string
 */
export function dealerDetailCuxiaoPath(dealerId: string, query?: Record<string, string>) {
  const q = qs.stringify(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.dealerDetailCuxiao.replace('[dealerId]', String(dealerId)) + q
}
/**
 * 经销商详情新闻资讯
 * @returns string
 */
export function dealerDetailNewsPath(dealerId: string, query?: Record<string, string>) {
  const q = qs.stringify(query, { addQueryPrefix: true })
  return WEB_ROUTE_PATH.dealerDetailNews.replace('[dealerId]', String(dealerId)) + q
}
/**
 * 经销商详情公司介绍
 * @returns string
 */
export function dealerDetailIntroducePath(dealerId: string) {
  return WEB_ROUTE_PATH.dealerDetailIntroduce.replace('[dealerId]', String(dealerId))
}

/**
 * 经销商详情车系详情
 * @returns string
 */
export function dealerDetailSeriesPath(dealerId: string, seriesId: string) {
  return WEB_ROUTE_PATH.dealerDetailSeries
    .replace('[dealerId]', dealerId)
    .replace('[seriesId]', seriesId)
}
/**
 * 经销商详情车型详情
 * @returns string
 */
export function dealerDetailModelDetailPath(dealerId: string, seriesId: string, modelId: string) {
  return WEB_ROUTE_PATH.dealerDetailModelDetail
    .replace('[dealerId]', dealerId)
    .replace('[seriesId]', seriesId)
    .replace('[modelId]', modelId)
}
/**
 * 经销商详情询价试驾
 * @param dealerId 经销商id
 * @param seriesId 车系id
 * @param modelId 车型id
 * @param type 'sj' | 'xj' 试驾 | 询价
 * @returns string
 */
export function dealerDetailXjSjPath(data: {
  dealerId?: string
  brandId?: string
  brandName?: string
  vendorId?: string
  vendorName?: string
  seriesId?: string
  seriesName?: string
  modelId?: string
  modelName?: string
  type?: 'sj' | 'xj'
}) {
  const { dealerId } = data
  const url = WEB_ROUTE_PATH.dealerDetailXjSj.replace('[dealerId]', String(dealerId))

  delete data.dealerId

  const q = qs.stringify(data, { addQueryPrefix: true })
  return `${url}${q}`
}
/** ******************************** 经销商 *********************************** **/
/** ****************************************************************************/

/**
 * 搜索页面
 * @returns string
 */
export function searchPath(keyword?: string) {
  return WEB_ROUTE_PATH.search + (keyword ? `?keyword=${keyword}` : '')
}
export function searchUserPath(query: { title?: string; keyword?: string; sysUserName?: string; authorName?: string; page?: string; articleType?: string }) {
  if (query.title) {
    query.keyword = query.title
    delete query.title
  }
  const q = qs.stringify(query)
  return `${WEB_ROUTE_PATH.search}?${q}`
}

/**
 * 专题详情跳转 -> 资讯详情 | 专题 | 外链
 * @param type string
 * @param article { id?: string; linkUrl?: string }
 */
export function topicDetailToDetailPath(data: {
  topicId: string | undefined
  topicComponentDataType: TOPIC_COMPONENT_DATA_TYPE
  topicComponentType: TOPIC_COMPONENT_TYPE
  articleType: ARTICLE_TYPE | undefined
  bannerType: BANNER_DATA_TYPE | undefined
  linkUrl: string | undefined
  dataId: string | undefined
}) {
  switch (data.topicComponentDataType) {
    case TOPIC_COMPONENT_DATA_TYPE.ARTICLE:
      return data.articleType ? articleDetailPath(data.articleType, { ...data }) : addHttp(data.linkUrl || '')
    case TOPIC_COMPONENT_DATA_TYPE.BANNER:
      return data.bannerType
        ? swiperPath(data.bannerType, { articleType: data.articleType, id: data.dataId, url: data.linkUrl })
        : addHttp(data.linkUrl || '')
    case TOPIC_COMPONENT_DATA_TYPE.TOPIC:
      return topicDetailPath(data.dataId || '')
    default:
      return newsDetailPath(data.dataId || '')
  }
}

export function swiperPath(type: BANNER_DATA_TYPE, item: { articleType?: ARTICLE_TYPE; id?: string; url?: string }) {
  let url = item.url
  if (type === BANNER_DATA_TYPE.ARTICLE && item.id)
    url = articleDetailPath(item.articleType || ARTICLE_TYPE.PICTURE, { id: item.id, linkUrl: item.url })
  else if (type === BANNER_DATA_TYPE.TOPIC && item.id)
    url = topicDetailPath(item.id)
  else if (type === BANNER_DATA_TYPE.LIVE && item.id)
    url = liveDetailPath(item.id)

  return `${url + (url?.includes('?') ? '&' : '?')}swiper=true`
}
// TODO: type
export function recommendPath(type: ARTICLE_TYPE, item: ArticleRecommendItem) {
  if (type === ARTICLE_TYPE.LINK && item.linkUrl)
    return item.linkUrl
  else if (type === ARTICLE_TYPE.VIDEO)
    return videoDetailPath(item.id)
  else
    return newsDetailPath(item.id)
}

/** **************** 页面跳转方法 *************/

/**
 * 跳转到登录页面
 * @param query LoginPageQuery
 */
export function goLoginPage(query?: LoginPageQuery, platform: RouterPlatform = false) {
  // 可能由于作用域变化无法正常获取到router信息
  const url = loginPath(query)
  goAnyPage(url, platform)
}

/**
 * 跳转到相关进销商询价试驾页面
 * @param dealerId 经销商id
 * @param seriesId 车系id
 * @param modelId 车型id
 * @param type 'sj' | 'xj' 试驾 | 询价
 */
export function goXjSjPage(data: {
  dealerId: string
  seriesId?: string
  modelId?: string
  type?: 'sj' | 'xj'
  carName: string
}, platform: RouterPlatform = false) {
  const url = dealerDetailXjSjPath(data)

  goAnyPage(url, platform)
}
