import { isClient } from '@qcwp/utils'
import type { Router } from 'vue-router'
import { useRouter } from 'vue-router'
import { BASE_HOST_EV, BASE_HOST_USER, BASE_HOST_WEB } from '../const'
import type { RouterPlatform } from './type'
import { homePath } from './web'
export * from './web'
export * from './user'
export * from './ev'

export enum WEB_ROUTE_PATH {
  home = '/', // 首页

  login = '/login', // 登录
  register = '/login/register', // 注册
  forget = '/login/forget', // 忘记密码

  /** ***************** 资讯 *****************/
  mediaHome = '/article/mediaIndex', // 网评号首页
  newsType = '/article/articleType/[typeId]', // 资讯分类
  articleAuthor = '/article/authorArticle/[authorId]', // 作者详情

  newsHome = '/news', // 资讯首页 old:/news/aChannel
  newsTopicPath = '/news/ztChannel', // 专题大全
  newsTopicDetailPath = '/zhuanti/[topicId]', // 专题大全
  newsHangQingPath = '/article/articleType/6', // 行情导购
  newsDetail = '/news/[articleId]', // 资讯详情

  videoHome = '/video',
  videoList = '/video/list',
  videoDetail = '/video/[videoId]',
  liveHome = '/live/',
  liveDetail = '/live/[liveId]',

  /** ***************** 资讯 *****************/

  /** ***************** 汽车 *****************/
  carHome = '/car/zhaoche', // 汽车首页
  carZhHome = '/car/zhaocheZh', // 综合筛选 0_0-0_0_0_0_0_0_0
  carTestDrive = '/car/TestDrive',
  seriesHome = '/series/[seriesId]/', // 车系详情首页
  seriesParameter = '/series/[seriesId]/parameter', // 车系详情参数
  seriesPrice = '/series/[seriesId]/price', // 车系详情价格
  inquiryPrice = '/series/[seriesId]/price/inquiry/[modelId]', // 车型询价
  seriesPraise = '/series/[seriesId]/praise', // 车系详情口碑
  seriesArticle = '/series/[seriesId]/article', // 车系详情资讯
  seriesArticleVideo = '/series/[seriesId]/video', // 车系详情资讯
  carImgHome = '/car/carTuku', // 车辆图库首页
  carImgSeries = '/car/seriesImg/[seriesId]', // 车系图库
  carDuibi = '/car/duibi', // 对比
  /** ***************** 汽车 *****************/
  /** ***************** 口碑 *****************/
  reputationHome = '/koubei', // 口碑首页
  reputationPublish = '/koubei/publishPraise', // 口碑首页

  /** ***************** 口碑 *****************/

  /** ***************** 经销商 *****************/
  dealerFind = '/findDealer', // 全部经销商首页
  dealerDetail = '/dealer/[dealerId]', // 经销商首页
  dealerDetailModel = '/dealer/[dealerId]/model', // 经销商详情车型报价
  dealerDetailCuxiao = '/dealer/[dealerId]/cuxiao', // 经销商详情促销信息
  dealerDetailNews = '/dealer/[dealerId]/news', // 经销商详情新闻资讯
  dealerDetailIntroduce = '/dealer/[dealerId]/introduce', // 经销商详情公司介绍
  dealerDetailComplaint = '/dealer/[dealerId]/complaint', // 经销商详情购车投诉
  dealerDetailSeries = '/dealer/[dealerId]/dealerCarDetail/[seriesId]', // 经销商详情销售车系详情
  dealerDetailModelDetail = '/dealer/[dealerId]/dealerCarDetail/[seriesId]/[modelId]', // 经销商详情销售车型详情
  dealerDetailXjSj = '/dealer/[dealerId]/xjsj', // 经销商详情询价和试驾
  /** ***************** 经销商 *****************/

  search = '/doArticle_search',
  daily = '/article/daily',
}
export enum USER_ROUTE_PATH {
  home = '/',
  apply = '/apply',
  edit = '/doArticle_edit',
  editVideo = '/doVideo_edit',
  editLive = '/doLive_edit',
  editError = '/doArticle_editError', // 编辑错误
  managementArticle = '/management/article',
  managementLivePath = '/management/live',
  managementComment = '/management/comment',
  managementCollection = '/management/collection',
  managementAttention = '/management/attention',
  dealerBase = '/dealer/base',
  dealerBanner = '/dealer/banner',
  dealerBannerEdit = '/dealer/banner/editor/',
  dealerIntroduce = '/dealer/introduce',
  dealerPromotion = '/dealer/promotion',
  dealerSales = '/dealer/sales',
  dealerConsult = '/dealer/consult',
  dealerSeries = '/dealer/series',
  dealerSeriesSelect = '/dealer/series/select',
  dealerModel = '/dealer/series/[seriesId]',
  dealerModelSelect = '/dealer/series/select',
  dealerModelDetail = '/dealer/series/[seriesId]/[modelId]?seriesName=[seriesName]&modelName=[modelName]',
}
export enum EV_ROUTE_PATH {
  home = '/',
  articleDetail = '/article/[articleId]',
  videoDetail = '/video/[videoId]',
  search = '/search',
}
export const ROUTE_PATH = {
  test: '/test',
  complaint: 'https://www.qctsw.com/', // 汽车投诉
  complaintDetail: 'https://www.qctsw.com/detail/[complaintId]', // 汽车投诉
  user: BASE_HOST_USER, // 用户管理后台
  web: BASE_HOST_WEB,
  ev: BASE_HOST_EV,
} as const

/**
 * 需要登录鉴权的页面
 */
export const AUTH_PATH = [ROUTE_PATH.test]

/**
 * 投诉
 * @returns string
 */
export function complaintPath() {
  return ROUTE_PATH.complaint
}
/**
 * 投诉详情
 * @returns string
 */
export function complaintDetailPath(complaintId: number) {
  return ROUTE_PATH.complaintDetail.replace('[complaintId]', String(complaintId))
}
/**
 * 用户管理后台
 * @returns string
 */
export function userPath() {
  const query = isClient ? window.location.href.split('?')[1] : ''
  return ROUTE_PATH.user + (query ? `?${query}` : '')
}
/**
 * 前台
 * @returns string
 */
export function webPath() {
  return ROUTE_PATH.web
}
export function evPath() {
  return ROUTE_PATH.ev
}

export function getHostPagePath(platform: RouterPlatform, url: string) {
  return (!platform || url.match(/^http/))
    ? url
    : `${platform === 'web'
            ? ROUTE_PATH.web
            : platform === 'ev'
              ? ROUTE_PATH.user
              : ROUTE_PATH.ev
        }${url}`
}

export function goAnyPage(url: string, platform: RouterPlatform = false, isNewWindow = false) {
  const router: Router | undefined = useRouter()
  const newUrl = getHostPagePath(platform, url)
  // console.log('goAnyPage', router, newUrl)
  if (isNewWindow)
    window.open(newUrl, '_blank')
  else if (!newUrl.includes('http') && router)
    router.push(newUrl)
  else
    window.location.href = newUrl
}
/**
 * Tips: setup中使用
 * 返回上一级页面
 */
export function goBackPage(platform: RouterPlatform = false) {
  if (!isClient)
    return
  const pageLen = history.length
  const router: Router | undefined = useRouter()

  if (router) {
    if (pageLen > 2)
      router.back()
    else
      router.replace(homePath())
  }
  else {
    if (pageLen > 2)
      history.back()
    else
      window.location.href = getHostPagePath(platform, homePath())
  }
}
