/**
 * 接口响应数据体
 */
export interface FetchResponseType<T = unknown> {
  code: number
  data: T
  msg: string
}
// 分页请求数据
export interface PaginationParams {
  pageNum: number
  pageSize: number
}

// 分页响应数据
export interface PaginationResponse<T> {
  pageNum: number
  pageSize: number
  total: number
  pages: number
  list: T
}

export interface Pagination {
  page: number // 当前页数
  pageSize: number // 每页数
  itemCount: number // 总条数
  pageCount: number // 总页数
  status: PaginationStatus
}
export type PaginationType = 'up' | 'pageNumber'
export enum PaginationStatus {
  INIT = 'INIT', // 初始化
  MORE = 'MORE', // 能够加载更多
  NO_MORE = 'NO_MORE', // 没有更多
  LOADING = 'LOADING', // 加载中
  ERROR = 'ERROR', // 加载失败
}

export interface PaginationOption {
  type?: PaginationType
  pageSize?: number
  pageNum?: number
}
export type ListState = 'loading' | 'load' | 'error' | 'finished'
