import type { ARTICLE_TYPE, THIRD_PLATFORM, USER_SEX, USER_STATUS, USER_SUBJECT_AUDIT_TYPE, USER_SUBJECT_TYPE, USER_TYPE } from './const'
/**
 * 用于获取某个函数返回值的类型
 * eg.
 * const MenuOptions = returnResultType(menuOptions)
 * type MenuOptionsType = typeof MenuOptions
 */
// 声明一个泛型类型别名，返回值与泛型类型相同，入参类型不限制。
export type Reverse<T> = (arg: any) => T
// 声明一个泛型方法，入参arg继承泛型约束，返回空对象并断言其类型为T
export function returnResultType<T>(_arg: Reverse<T>): T {
  return {} as T
}

/**
 * 用户类型
 */
export type MediaType = USER_TYPE
export type UserType = USER_TYPE
/**
 * 用户状态
 */
export type UserStatus = USER_STATUS
/**
 * 主体认证状态
 */
export type UserSubjectType = USER_SUBJECT_TYPE
/**
 * 字典码-网评号信息审核状态
 */
export type UserSubjectAuditType = USER_SUBJECT_AUDIT_TYPE

/**
 * 性别类型
 */
export type SexType = `${USER_SEX}`

/**
 * 登录方式
 */
export enum LoginWayEnum {
  ACCOUNT = 'ACCOUNT_PASSWORD', // 账号密码
  PHONE = 'PHONE_VERIF_CODE', // 手机验证码
  WX_OPEN = 'WX_OPEN', // 微信开发平台
  WX_MP = 'WX_MP', // 微信公众号
  WX_MINI = 'WX_MINIAPP', // 微信小程序
}

/**
 * 第三方授权平台
 */
export type ThirdPlatform = THIRD_PLATFORM

/**
 * time string
 */
export type TimeString = string
/**
 * Json string
 */
export type JsonString = string

export interface ArticleRecommendItem {
  articleType: ARTICLE_TYPE
  id: string
  imgs: string[]
  linkUrl: string
  showCreateTime: TimeString
  title: string
}
