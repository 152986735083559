import QRCode from 'qrcode'
/**
 *  生成二维码
 * @param url string
 * @returns qrcode base64
 */
export async function generateCode(url: string, options?: QRCode.QRCodeToDataURLOptions | undefined) {
  let src = ''
  try {
    src = await QRCode.toDataURL(url, options)
    return src
  }
  catch (error) {
    console.error(`generate qrcode error: ${error}`)
  }
}
