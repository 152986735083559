import { numFormat } from '@qcwp/utils'
import { generateCode } from '../function'
import type { PosterContext, PosterJson } from './core'
import { Poster, PosterType } from './core'

export interface ReadingQuantityPosterOptions {
  mediaName: string
  mediaLogo: string
  title: string
  href: string
  cover: string
  lookCount: number
  LOGO: string
}
const BASE_COLOR = '#000'
/**
 * 海报背景
 */
async function posterBackgroundHandle(cover: string, context: PosterContext): Promise<PosterJson> {
  // const { dpi } = context

  // const { img, color: rgb } = await generateGaussBlurImage({
  //   width: context.width,
  //   height: context.height,
  //   radius: 50,
  //   sigma: 50,
  //   src: imgSize(cover, context.width, context.height),
  // })
  // const [r, g, b] = rgb
  // // console.log(rgb, r * 0.299 + g * 0.587 + b * 0.114)
  // BASE_COLOR = (r * 0.213 + g * 0.715 + b * 0.072) > 255 / 2 ? '#0f172a' : '#f8fafc'

  return {
    type: PosterType.rect,
    // src: BG,
    x: 0,
    y: 0,
    width: context.width,
    height: context.height,
    bgColor: '#e2e8f0',
  }
}

function borderHandle(context: PosterContext): PosterJson {
  const offset = 10
  return {
    type: PosterType.line,
    x: 0,
    y: 0,
    paths: [
      [offset, offset, 'moveTo'],
      [context.width - offset, offset],
      [context.width - offset, context.height - offset],
      [offset, context.height - offset],
      [offset + 1, offset],
    ],
    width: context.width,
    height: context.height,
    color: BASE_COLOR,
    lineWidth: 2,
  }
}
/**
 * 内容展示区域
 */
function posterContentHandle(context: PosterContext): PosterJson {
  const { width, height } = context
  const offsetX = 30
  const offsetY = 10
  const contentWidth = context.width - offsetX
  const contentHeight = height - offsetY
  return {
    type: PosterType.rect,
    x: width / 2 - contentWidth / 2,
    y: offsetY,
    width: contentWidth,
    height: contentHeight,
    bgColor: '#ffffff',
    opacity: 1,
  }
}
function logoTipsHandle(LOGO: string, context: PosterContext, content: PosterJson): PosterJson[] {
  // const selfOffsetY = 20
  const scale = 0.8
  const contentWidth = 76 * scale
  const contentHeight = 43 * scale

  const text = '提供个性化汽车资讯'
  const font = {
    fontSize: 13,
    lineHeight: 1,
  } as const
  const fontWidth = context.getTextWidth(text, context.font(font))
  const fontHeight = font.fontSize * font.lineHeight

  const gap = 5
  const startX = content.x + (content.width - contentWidth - fontWidth - gap) / 2
  const StartY = content.y + 20 // + content.height - contentHeight - selfOffsetY
  const logoJson: PosterJson = {
    type: PosterType.image,
    src: LOGO,
    x: startX,
    y: StartY,
    width: contentWidth,
    height: contentHeight,
  }
  const tipsJson: PosterJson = {
    type: PosterType.text,
    text,
    x: startX + contentWidth + gap,
    y: StartY + (contentHeight - fontHeight) / 2,
    width: fontWidth,
    height: fontHeight,
    ...font,
    textBaseline: 'top',
    color: BASE_COLOR,
  }
  return [logoJson, tipsJson]
}

function qrcodeJsonHandle(qrcode: string, context: PosterContext, content: PosterJson): PosterJson[] {
  const { width } = context
  const arrowSize = 30
  const contentWidth = 80
  const contentHeight = 80
  const qrcodeJson: PosterJson = {
    type: PosterType.image,
    src: qrcode,
    x: (width - contentWidth) / 2,
    y: content.y + content.height - contentHeight - 25,
    width: contentWidth,
    height: contentHeight,
  }

  const text = '扫描二维码浏览文章'
  const font = {
    fontSize: 13,
    lineHeight: 1,
  } as const
  const textWidth = context.getTextWidth(text, context.font(font))
  const textHeight = font.fontSize * font.lineHeight
  const qrcodeTipsJson: PosterJson = {
    type: PosterType.text,
    text,
    x: (width - textWidth) / 2,
    y: qrcodeJson.y - textHeight - 5 - arrowSize / 2,
    width: textWidth,
    height: textHeight,
    ...font,
    color: BASE_COLOR,
  }

  const arrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><g fill="${BASE_COLOR}"><path d="m8.303 12.404l3.327 3.431c.213.22.527.22.74 0l6.43-6.63C19.201 8.79 18.958 8 18.43 8h-5.723l-4.404 4.404Z"/><path d="M11.293 8H5.57c-.528 0-.771.79-.37 1.205l2.406 2.481L11.293 8Z" opacity=".5"/></g></svg>`

  const arrowJson: PosterJson = {
    type: PosterType.image,
    src: `data:image/svg+xml;base64,${window.btoa(arrowSvg)}`,
    x: (width - arrowSize) / 2,
    y: qrcodeJson.y - arrowSize,
    width: arrowSize,
    height: arrowSize,
  }
  return [qrcodeJson, qrcodeTipsJson, arrowJson]
}

function articleJsonHandle(options: {
  title: string
  mediaName: string
  mediaLogo: string
  cover: string
}, context: PosterContext, content: PosterJson): PosterJson[] {
  const { title, mediaName, mediaLogo, cover } = options
  const width = content.width - 20

  const mediaSize = 35
  const coverWidth = width
  const coverHeight = coverWidth * 0.5625
  const titleFont = {
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 1.4,
  } as const
  const mediaNameFont = {
    fontSize: mediaSize / 6 * 3,
    lineHeight: 1,
  } as const
  // const titleGap = mediaSize / 3 * 2 + 15

  const gap = 20
  const mediaPaddingX = 10
  const startX = content.x + mediaPaddingX
  const startY = content.y + gap + 60

  let titleLine = context.getTextLineCount(coverWidth, title, context.font(titleFont))
  titleLine = titleLine > 2 ? 2 : titleLine

  const titleJson: PosterJson = {
    type: PosterType.textEllipsis,
    text: title,
    x: startX,
    y: startY,
    width: coverWidth,
    height: titleFont.fontSize * titleFont.lineHeight * titleLine,
    ...titleFont,
    color: BASE_COLOR,
    textBaseline: 'top',
  }
  const coverJson: PosterJson = {
    type: PosterType.image,
    src: cover,
    x: startX,
    y: startY + titleJson.height + gap,
    width: coverWidth,
    height: coverHeight,
    boxRadius: 8,
  }
  const mediaLogoJson: PosterJson[] = [
    {
      type: PosterType.rect,
      x: startX,
      y: coverJson.y + coverHeight + gap / 2,
      width: mediaSize,
      height: mediaSize,
      boxRadius: mediaSize / 2,
      bgColor: '#ffffff',
    },
    {
      type: PosterType.image,
      src: mediaLogo,
      x: startX,
      y: startY + coverHeight + titleJson.height + 30,
      width: mediaSize,
      height: mediaSize,
      boxRadius: mediaSize / 2,
    },
  ]
  const mediaNameJson: PosterJson = {
    type: PosterType.text,
    text: `${mediaName}`,
    x: startX + mediaSize + mediaPaddingX,
    y: startY + coverHeight + titleJson.height + 30 + mediaSize / 2 - mediaNameFont.fontSize / 3,
    width: coverWidth - mediaSize - mediaPaddingX * 2,
    height: mediaSize / 3 * 2,
    ...mediaNameFont,
    color: BASE_COLOR,
    textBaseline: 'top',
  }

  return [coverJson, titleJson, ...mediaLogoJson, mediaNameJson]
}

function lookCountJsonHandle(lookCount: number, context: PosterContext, content: PosterJson, options: { offsetY: number }): PosterJson[] {
  const { width } = context
  const { offsetY } = options
  const text = `${numFormat(lookCount)}`
  const countFont = {
    fontSize: 50,
    lineHeight: 1,
    fontWeight: 'bolder',
  } as const
  const font = {
    fontSize: 15,
    lineHeight: 1,
  } as const
  const textWidth = context.getTextWidth(text, context.font(countFont))
  const textHeight = countFont.fontSize * countFont.lineHeight

  const tips = '次阅读'
  const tipsWidth = context.getTextWidth(tips, context.font(font))

  const startX = (width - textWidth - tipsWidth) / 2
  const startY = offsetY + (content.height - offsetY - 190) / 2

  const color1 = '#171717'
  const color2 = '#71717a'
  const { dpi } = context
  const gradient = context.canvasContext.createLinearGradient(startX * dpi, startY * dpi, (startX + textWidth) * dpi, (startY + textHeight) * dpi)
  gradient.addColorStop(0, color1)
  gradient.addColorStop(0.1, color1)
  gradient.addColorStop(0.2, color2)
  gradient.addColorStop(0.3, color1)
  gradient.addColorStop(0.4, color2)
  gradient.addColorStop(0.5, color1)
  gradient.addColorStop(0.6, color1)
  gradient.addColorStop(0.7, color2)
  gradient.addColorStop(0.8, color1)
  gradient.addColorStop(1, color1)

  const lookCountJson: PosterJson = {
    type: PosterType.text,
    text,
    x: startX,
    y: startY,
    width: textWidth,
    height: textHeight,
    ...countFont,
    // color: '#171717',
    color: gradient,
    textBaseline: 'top',
    letterSpacing: 1,
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 5,
    shadowColor: '#a3a3a3',
  }
  const tipsJson: PosterJson = {
    type: PosterType.text,
    text: tips,
    x: lookCountJson.x + lookCountJson.width + 5,
    y: lookCountJson.y + lookCountJson.height - font.fontSize * font.lineHeight - countFont.fontSize / 10,
    width: 20,
    height: textHeight,
    ...font,
    color: '#171717',
    textBaseline: 'top',
  }

  // const rect: PosterJson = {
  //   type: PosterType.rect,
  //   x: startX,
  //   y: startY,
  //   width: textWidth,
  //   height: textHeight,
  //   bgColor: gradient,
  // }
  return [lookCountJson, tipsJson]
}
/**
 * 生成海报
 */
export async function createReadingQuantityPoster(options: ReadingQuantityPosterOptions, proxy?: (url: string) => Promise<string>) {
  const { mediaName, mediaLogo, title, href, cover, lookCount, LOGO } = options || {}
  const poster = new Poster({ scale: 3, cors: true, width: 375, height: 660, proxy })
  const qrcode = await generateCode(href, { color: { dark: '#000', light: '#ffffffff' } }) || ''
  // 内容区域
  const content = poster.generateDraw(posterContentHandle)
  let contentJson: PosterJson[] = []

  const result = await poster.generateDrawAsync(ctx => posterBackgroundHandle(cover, ctx))
  contentJson.push(result)
  // logo
  const logoJson = poster.generateDraw(ctx => logoTipsHandle(LOGO, ctx, content))
  // 二维码
  const qrcodeJson = poster.generateDraw(context => qrcodeJsonHandle(qrcode, context, content))
  // 文章
  const articleJson = poster.generateDraw(context => articleJsonHandle({
    title,
    mediaName,
    mediaLogo,
    cover,
  }, context, content))
  const offsetY = articleJson[articleJson.length - 1].y + articleJson[articleJson.length - 1].height
  // console.log(articleJson, offsetY)
  const lookCountJson = poster.generateDraw(context => lookCountJsonHandle(lookCount, context, content, { offsetY }))
  const borderJson = poster.generateDraw(ctx => borderHandle(ctx))
  contentJson.push(
    borderJson,
    ...logoJson,
    ...qrcodeJson,
    ...articleJson,
    ...lookCountJson,
    // preTextJson, countTextJson, unitTextJson,
  )
  contentJson = contentJson.filter(item => item)
  // eslint-disable-next-line no-console
  console.log(contentJson)
  return await poster.create(contentJson)
}

export interface DefaultPosterOptions {
  href: string
  title: string
  mediaName: string
  mediaType: any
  authorName?: string
  sysUserName?: string
  mediaLogo: string
  cover: string
  lookCount?: number
  LOGO?: string
}
export function useReadingQuantityPoster(options: DefaultPosterOptions, proxy?: (url: string) => Promise<string>) {
  const newOptions = {
    mediaName: options.mediaName,
    mediaLogo: options.mediaLogo,
    title: options.title,
    href: options.href,
    cover: options.cover,
    lookCount: options.lookCount || 0,
    LOGO: options.LOGO || '',
  }
  function createPoster() {
    return createReadingQuantityPoster(newOptions, proxy)
  }
  return {
    createPoster,
  }
}
