import { LOAD_STATUS } from '../../const/enum'
import type { ListState } from './type'
import { PaginationStatus } from './type'

/**
 *
 * @param paginationStatus PaginationStatus
 * @returns
 */
export function getPaginationListState(paginationStatus: PaginationStatus): ListState {
  switch (paginationStatus) {
    case PaginationStatus.NO_MORE:
      return 'finished'
    case PaginationStatus.ERROR:
      return 'error'
    case PaginationStatus.LOADING:
      return 'loading'
    default:
      return 'load'
  }
}

export function getPaginationStateText(options: {
  [key in PaginationStatus]: string
} = {
  [PaginationStatus.LOADING]: '加载中...',
  [PaginationStatus.NO_MORE]: '没有更多了',
  [PaginationStatus.ERROR]: '加载失败',
  [PaginationStatus.INIT]: '加载更多',
  [PaginationStatus.MORE]: '加载更多',
}) {
  return (status?: PaginationStatus) => {
    return status ? (options[status] || options.NO_MORE) : options.NO_MORE
  }
}

//  ListState toLOAD_STATUS
export function listStateToStatus(state: ListState): LOAD_STATUS {
  if (state === 'loading')
    return LOAD_STATUS.LOADING
  else if (state === 'error')
    return LOAD_STATUS.ERROR

  else if (state === 'finished')
    return LOAD_STATUS.END
  else
    return LOAD_STATUS.LOAD_MORE
}
